import { Stack } from "@mui/material";
import { Tabs, Page, Spinner } from "@shopify/polaris";
import { NotificationEmails as NotificationEmailsEnum } from "@smartrr/shared/entities/CustomerEmailConfig";
import { isEmailValid } from "@smartrr/shared/utils/isEmailValid";
import { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";

import { EditEmail } from "./EmailSettings/components/EditEmail";
import { EditTemplates } from "./EmailSettings/components/EditTemplates";
import { EmailNotifications } from "./EmailSettings/components/EmailNotifications";
import { SmsSettings } from "./SmsSettings";
import { TabsContainer } from "../AdminThemingRoute/styles";
import {
  CustomerNotificationViews,
  ICustomerEmailConfig,
  ICustomerEmailPageProps,
  IEmailPreview,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { noawait } from "@smartrr/shared/utils/noawait";
import retry from "async-retry";
import { ThemingStoreAccess } from "../AdminThemingRoute/store";
import { SetupStoreAccess } from "../AdminSetupRoute/libs/store";

const PageWrapper = styled.div<{ fitted: boolean }>`
  display: flex;
  justify-content: center;

  & .Polaris-Page {
    padding: 0;
    width: ${({ fitted }) => (fitted ? "670px" : "998px")};

    .Polaris-Tabs__Wrapper {
      width: ${({ fitted }) => (fitted ? "100%" : "80%")};
    }
  }
`;

enum BillingSettingsTab {
  EMAIL,
  SMS,
}

const tabs = [
  { id: "emails", content: "Email Notifications" },
  { id: "sms", content: "SMS Notifications" },
];

export function AdminSubscriberNotificationsRoute(): JSX.Element {
  const { addToast } = useToast();
  const { errorMessage, showError } = EmailNotificationAccessStore.useErrors();
  const isLoading = EmailNotificationAccessStore.useLoading();
  const { initialize: initializeEmailNotifications } = EmailNotificationAccessStore.useActions();
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();
  const { get: getCustomerPortalTheme } = ThemingStoreAccess.useActions();
  const { initialize: initializeSetup } = SetupStoreAccess.useActions();

  useEffect(() => {
    noawait(async () => await retry(initializeEmailNotifications, { retries: 3 }));
  }, [!emailConfigs]);

  useEffect(() => {
    if (showError && errorMessage) {
      addToast(errorMessage, true);
    }
    // Customer portal theme is needed to retrieve translation specific information within child components
    noawait(() => getCustomerPortalTheme());
  }, [showError]);

  useEffect(() => {
    initializeSetup(true);
  }, []);

  if (isLoading || !emailConfigs) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh" width="100%">
        <Spinner />
      </Stack>
    );
  }

  return <SubscriberNotificationsInner />;
}

const SubscriberNotificationsInner = () => {
  const [selectedTab, setSelectedTab] = useState(BillingSettingsTab.EMAIL);
  const [view, setView] = useState<{ view: CustomerNotificationViews; emailType?: NotificationEmailsEnum }>({
    view: CustomerNotificationViews.DEFAULT,
  });

  const { addToast } = useToast();

  const useFittedView = useMemo(
    () => view.view === CustomerNotificationViews.DEFAULT || view.view === CustomerNotificationViews.EDIT_EMAIL,
    [view]
  );

  const areValidChanges = useCallback(
    (formValues: RequireNilFields<ICustomerEmailConfig>, emailConfig: IEmailPreview): boolean => {
      //TODO: add zod validation here instead
      //* Add all additional validation here
      const bccAddressVal = formValues[emailConfig.configValues?.bccAddress!];
      const sendBccToggleVal = formValues[emailConfig.configValues?.sendBccAddress!];

      // If BCC email is valid
      if ((bccAddressVal && !isEmailValid(bccAddressVal as string)) || (sendBccToggleVal && !bccAddressVal)) {
        addToast("Please enter a valid BCC email address", true);
        return false;
      }
      return true;
    },
    []
  );

  const props: ICustomerEmailPageProps = {
    setView,
    areValidChanges,
  };

  const InnerComponent = useMemo(() => {
    switch (view.view) {
      case CustomerNotificationViews.DEFAULT: {
        return (
          <TabsContainer style={{ border: "none" }}>
            <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} fitted>
              <div style={{ paddingTop: 20 }}>
                {selectedTab === BillingSettingsTab.EMAIL ? (
                  <EmailNotifications {...props} />
                ) : selectedTab === BillingSettingsTab.SMS ? (
                  <SmsSettings />
                ) : null}
              </div>
            </Tabs>
          </TabsContainer>
        );
      }
      // TODO: Move each view to it's own dedicated route opposed to swapping components
      case CustomerNotificationViews.EDIT_TEMPLATES: {
        return <EditTemplates {...props} />;
      }
      case CustomerNotificationViews.EDIT_EMAIL: {
        return <EditEmail {...props} navigationView={view} />;
      }
      default: {
        return <React.Fragment />;
      }
    }
  }, [selectedTab, view]);

  return (
    <PageWrapper fitted={useFittedView}>
      <Page fullWidth>{InnerComponent}</Page>
    </PageWrapper>
  );
};
